body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* .react-grid-Grid {  height: 70vh } */
.react-grid-Canvas {
  min-width: 100% !important;
}
