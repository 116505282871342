  .arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 16px solid orange;
    border-radius: 3px;
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    border-radius: 3px;
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    border-radius: 3px;
  }

  .arrow-left {
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    border-radius: 3px;
  }

  .spacer {
    height: 6px;
    width: 100%;
  }